import React, { useEffect } from 'react';
import { CheckToggle, Heading, Modal, MessageModalLayout, Text, Card, Box, Page, Button, Cell, Layout, WixDesignSystemProvider, PageSection, MarketingLayout, AnnouncementModalLayout, WixStyleReactProvider, Loader } from '@wix/design-system';
import "@wix/design-system/styles.global.css";
import * as Icons from '@wix/wix-ui-icons-common';
import './App.css';
import { ReactTagManager } from 'react-gtm-ts';

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}

function App() {
  ReactTagManager.init(tagManagerArgs)
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: ""
    }
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
  }, [])

  const token = new URLSearchParams(window.location.search).get('token');
  const instance = new URLSearchParams(window.location.search).get('instance')

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=7dedf94b-e627-4616-aa73-7d28311e671f&redirectUrl=https://certifiedcode.editorx.io/crypto/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixStyleReactProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Header
            title="Crypto"
            subtitle="Display Live Rates"
          />
          <Page.Content>
            <Loader size="large" />
          </Page.Content>
        </Page>
      </WixStyleReactProvider>
    )
  }

  // if (!instance) {
  //   return null
  // }

  const BASE_URL = `https://certifiedcode.editorx.io/crypto/_functions`

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash
          });
        }
      })
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {
        setIsModalOpen(true)
      })
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Header
          title={<Box gap={"SP2"} alignItems="middle">
            <Heading>Crypto: Display Live Rates</Heading>
          </Box>}
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              <Button skin="premium" disabled={isUpgraded} prefixIcon={<Icons.PremiumFilled />} onClick={() => setIsUpgradeModalOpen(true)}>Upgrade to show rates</Button>
            </Box>
          }
        />
        <Page.Content>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="premium"
              title="Show Live Rates on your Crypto Widget"
              primaryButtonText="Upgrade"
              linkText="Learn more"
              primaryButtonOnClick={() => { window.open(`https://www.wix.com/apps/upgrade/${"7dedf94b-e627-4616-aa73-7d28311e671f"}?appInstanceId=${(instanceData as any)['instance']['instanceId']} `) }}
              linkOnClick={() => { window.Intercom('showArticle', 7976685) }}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>
                No ads, no affilate links on your Crypto Widget!
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal
            isOpen={isModalOpen}
            screen="desktop"
          ><MessageModalLayout
              primaryButtonText="Refresh"
              primaryButtonOnClick={() => window.location.reload()}
              title="Sorry, something went wrong"
              content={
                <Text>Please try again later.</Text>
              }
            />
          </Modal>
          <Layout>
            <Cell span={12}>
              <Card>
                <Card.Header
                  title="Live Crpyto Rates"
                  suffix={
                    <CheckToggle size="medium" checked={isUpgraded}
                    onChange={()=>{
                      if (!isUpgraded) {
                        setIsUpgradeModalOpen(true)
                      }
                    }}
                    skin={isUpgraded ? "success" : 'standard'} />}
                />
              </Card>
            </Cell>
            <Cell>
              <PageSection title="Recommended for you" showDivider />
            </Cell>
            <Cell span={6}>
              <Card>
                <MarketingLayout
                  title="Learn how to setup your Crypto Widget"
                  actions={<Button size="small" onClick={() => { window.Intercom("showArticle", 7976685) }}>Learn how</Button>}
                  size="tiny"
                />
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
